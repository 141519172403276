.horizontal-slider {
  width: 250px;
  max-width: 250px;;
}
.example-thumb {
  cursor: pointer;
  position: absolute;
  z-index: 100;
  background: #ffffff;
  display: block;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 44%);
  border-radius: 3px;
}
.example-track {
  position: relative;
}
.example-track.example-track-0 {
  display: none;
}
.example-track.example-track-1 {
  background: white;
  opacity: 0.5;
}
.horizontal-slider .example-track {
  top: 10px;
  height: 40px;
}
.horizontal-slider .example-thumb {
  top: 10px;
  width: 5px;
  outline: none;
  height: 40px;
  line-height: 38px;
}
.thumb-text {
  display: none;
}
.example-thumb.example-thumb-0 {
  margin-right: 4px;
}
.example-thumb.example-thumb-1 {
  margin-left: 4px;
}
.progress-bar {
  --seek-before-width: 0;
  --knobby: white;
  --selectedKnobby: #ec0505;
  --bar-height: 9px;

  appearance: none;
  background: var(--bar-bg);;
  border-radius: 10px;
  width: 250px;
  height: var(--bar-height);
  outline: none;
  top: 195px;
  position: absolute;
  z-index: 3;
}

/* progress bar - safari */
.progress-bar::-webkit-slider-runnable-track {
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

/* progress bar - firefox */
.progress-bar::-moz-range-track {
  background: var(--bar-bg);;
  border-radius: 10px;
  position: relative;
  width: 100%;
  height: var(--bar-height);
  outline: none;
}

.progress-bar::-moz-focus-outer {
  border: 0;
}

/* progress bar - chrome and safari */
.progress-bar::before {
  content: '';
  height: var(--bar-height);
  width: var(--seek-before-width);
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  cursor: pointer;
}

/* progress bar - firefox */
.progress-bar::-moz-range-progress {
  background-color: var(--seek-before-color);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: var(--bar-height);
}

/* knobby - chrome and safari */
.progress-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 38px;;
  width: 2px;
  border: none;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 2;
  box-sizing: border-box;
}

/* knobby while dragging - chrome and safari */
.progress-bar:active::-webkit-slider-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}

/* knobby - firefox */
.progress-bar::-moz-range-thumb {
  height: 40px;
  width: 3px;
  border-radius: 50%;
  border: transparent;
  background-color: var(--knobby);
  cursor: pointer;
  position: relative;
  z-index: 3;
  box-sizing: border-box;
}

/* knobby while dragging - firefox */
.progress-bar:active::-moz-range-thumb {
  transform: scale(1.2);
  background: var(--selectedKnobby);
}