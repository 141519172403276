.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25em;
  height: 34em;
  border: 1px solid rgb(204, 204, 204);
  background: rgb(0, 0, 0);
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}

.overlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(255, 255, 255, 0.75);
}

@media (max-width:600px) {
  .modal {
    width: 20em;
    height: 28em;
  }
}